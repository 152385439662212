import React from 'react';
import {Container, Row, Col, Card} from 'react-bootstrap';
import './OutOfCreditPage.css';
import CheckoutPage from '../CheckoutPage/CheckoutPage';
import {useLogin} from '../UserAutentication/LoginProvider';
import PaywallPage from '../PaywallPage/PaywallPage';
import OneTimePaywallPage from "../PaywallPage/OneTimePaywallPage";

const OutOfCreditPage = () => {
    const {hasActiveSubscription} = useLogin();

    // Active user without credit will get hard offer checkout
    const product = {productId: 1, planType: 'one-time', finalPrice: 9};

    //show hard offer for accounts with active subscription (they cant buy another subscription)
    //show paywall (with subscriptions) to users that dont have active subscription - basic or cancelled
    return (
        hasActiveSubscription ? (
            <Container fluid className="google-login-page">
                <Row className="justify-content-center">
                    <Col className="d-flex justify-content-center"> {/* Full width column */}
                        <Card className="text-center shadow-lg p-4 mb-5 bg-white rounded custom-card">
                            <Card.Body>
                                <Card.Title className="display-4 mb-2">You are out of AI credit :(</Card.Title>
                                <Card.Text className="lead mb-4">
                                    Please purchase more credit continue enjoying TattooGPT.Art
                                </Card.Text>
                                <h4>Enjoy 100 credits for only $9</h4>
                                <p>Choose your payment method:</p>
                                <CheckoutPage product={product}/>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        ) : (
            <>
                <br/><br/><br/>
                <div className="out-of-credit-paywall">
                    <div className="out-of-credit-paywall-paywall">
                        <br/>
                        <OneTimePaywallPage title={"Get More AI Credits!"}/>
                        <br/>
                        <br/>
                        <br/>
                    </div>
                </div>

            </>
        )
    );
};

export default OutOfCreditPage;
