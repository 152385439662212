import React from 'react';
import OneTimePaywallPage from "../OneTimePaywallPage";
import "./PricingPage.css"
import {Helmet} from "react-helmet";

const PricingPage = () => {

    return (
        <>
            <Helmet>
                <title>Pricing | TattooGPT</title>
                <meta name="description" content="Discover our flexible pricing plans for AI-generated tattoo designs. Choose the plan that fits your needs and start creating stunning tattoos today!" />
                <meta name="keywords" content="Tattoo Pricing, AI Tattoos, Tattoo Plans, Subscription Plans, Custom Tattoos" />
                <link rel="canonical" href="https://www.tattoogpt.art/Pricing" />
            </Helmet>

            <div className="PricingPage">
                <OneTimePaywallPage/>
            </div>
        </>
    );
}

export default PricingPage;