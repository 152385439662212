import React, {useState, useEffect} from 'react';
import axios from 'axios';
import './OneTimePaywallPage.css';
import CheckoutPage from "../CheckoutPage/CheckoutPage";
import {useLogin} from "../UserAutentication/LoginProvider";

const OneTimePaywallPage = ({title = null, ImageId=null}) => {
    const {isLoggedIn} = useLogin();
    const [selectedPlan, setSelectedPlan] = useState('Pro'); // Default to Pro plan
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Add loading state
    const PaywallTitle = title == null ? "Get More AI Credits!" : title;

    const plans = [
        {
            name: 'Starter',
            color: '#B0B0B0', // Muted grayish gold
            credits: 10,
            productId: 11
        },
        {
            name: 'Pro',
            color: '#257bda', // Vibrant, trustworthy blue
            credits: 100,
            productId: 14
        },
        {
            name: 'Premium',
            color: '#8E44AD', // Luxurious deep purple
            credits: 200,
            productId: 12
        },
    ];

    useEffect(() => {
        fetchProducts();
    }, []);

    useEffect(() => {
        if (products.length > 0) {
            const productId = getProductId();
            const product = getProductById(productId);
            setSelectedProduct(product);
            setIsLoading(false); // Stop loading when products are set
        }
    }, [products, selectedPlan]);

    const fetchProducts = async () => {
        console.log("Fetching products from the server");
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_SERVER_URL + `/api/v1/products/getProducts`, {
                withCredentials: true
            });
            if (response.status === 200) {
                setProducts(response.data);
            }
        } catch (error) {
            console.error('Error fetching Products data:', error);
        }
    };

    const getProductById = (productId) => {
        return products.find(product => product.productId === productId);
    };

    const getProductId = () => {
        const plan = plans.find(p => p.name === selectedPlan);
        return plan.productId;
    };

    const renderPrice = (productId) => {
        const product = getProductById(productId);
        if (!product) return null;

        const savings = product.msrpPrice - product.finalPrice;

        return (
            <div className="price-container">
                <div className="price-row">
                    {product.msrpPrice !== product.finalPrice && (
                        <span className="strikethrough">${product.msrpPrice}</span>
                    )}
                    <h3 className="final-price">${product.finalPrice}</h3>
                </div>
                {product.msrpPrice !== product.finalPrice && (
                    <div className="you-save-row">
                        <span className="you-save">🎉 You save ${savings.toFixed(2)}</span>
                    </div>
                )}
            </div>


        );
    };

    const selectedPlanInfo = plans.find(plan => plan.name === selectedPlan);

    return (
        <div className="paywall">
            <h1 className="paywall-main-title">{PaywallTitle}</h1>
            <p className="paywall-second-title">
                Your purchase helps our startup grow and innovate. Thank you! ❤️
            </p>
            <div className="plan-switch">
                {plans.map(plan => (
                    <div
                        key={plan.name}
                        className={`switch-button ${selectedPlan === plan.name ? 'selected' : ''}`}
                        onClick={() => setSelectedPlan(plan.name)}
                    >
                        {plan.name}
                    </div>
                ))}
            </div>

            <div className="plans">
                <div
                    className={`plan ${selectedPlan.toLowerCase()} ${selectedPlan === selectedPlan ? 'selected' : ''}`}>
                    <ul>
                        <li className="first-paywall-benefit">
                            ✅ Create
                            <span style={{
                                color: selectedPlanInfo.color,
                                fontWeight: 'bold',
                                fontSize: 25
                            }}>{' ' + selectedPlanInfo.credits + ' '}</span>
                            Tattoo AI Designs
                        </li>
                        <li>✅ Control Tattoo Advanced complexity</li>
                        <li>✅ Access all Special ink Styles</li>
                        <li>✅ Best Resolution for Tattoo Downloads</li>
                        <li>✅ Unlimited access to your Gallery</li>
                        <li>✅ Priority Costumer Support</li>
                        <li>✅ Up to 2 Tattoos in parallel</li>
                        <li className="paywall-last-benefit">✅ Enjoy new features and Updates</li>
                    </ul>
                </div>
            </div>

            <div className="total-price">
                <h3>
                    {renderPrice(getProductId())}
                </h3>
            </div>

            <div className="paywall-checkout-buttons">
                {!isLoading && selectedProduct && (
                    <CheckoutPage key={`${selectedPlan}`} product={selectedProduct}/>
                )}
            </div>

        </div>
    );
};

export default OneTimePaywallPage;
