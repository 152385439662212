import React, { useState } from 'react';
import './ShortFAQ.css';


const faqData = [
    {
        question: "Is there a free tattoo AI?",
        answer: "Yes! TattooGPT.Art offers a free try that allows you to experience the AI-powered design process—no credit card required - Tattoo GPT Art"
    },
    {
        question: "Is there an AI for tattoos?",
        answer: "Yes, TattooGPT.Art uses advanced AI to create personalized tattoo designs based on your unique ideas, Tattoo GPT Art delivers high-quality, custom results in seconds."
    },
    {
        question: "Can AI design a realistic tattoo?",
        answer: "Absolutely! TattooGPT.Art specializes in transforming your vision into one-of-a-kind tattoo designs tailored to your style and preferences - Tattoo GPT Art"
    },
    {
        question: "Is TattooGPT.Art worth it?",
        answer: "WE THE BEST. Tattoo GPT Art offers awesome realistic value with powerful AI design capabilities that save you time and deliver better results than traditional design methods or other online services."
    },
    {
        question: "Is there an app to create a tattoo?",
        answer: "TattooGPT.Art is an online platform accessible from any device, it’s not a mobile app. Visit www.TattooGPT.Art to create your tattoo designs directly online."
    },
    {
        question: "What is the best AI tattoo generator?",
        answer: "Yes! TattooGPT.Art offers awesome realistic value with powerful AI design capabilities Tattoo GPT Art save you time and deliver better results than traditional design methods or other online services."
    },
    {
        question: "How to design a tattoo for yourself?",
        answer: "Simply! Signup to Tattoo GPT Art, Type your tattoo ideas, Choose style and we will do the rest"
    },
];

const ShortFAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="shortFAQ-container">
            <h2 className="shortFAQ-header">Frequently Asked Questions</h2>
            <div className="shortFAQ-section">
                {faqData.map((item, index) => (
                    <div key={index} className={`shortFAQ-item ${activeIndex === index ? 'open' : ''}`} onClick={() => toggleFAQ(index)}>
                        <h3 className="shortFAQ-item-title">{item.question} <span className={`shortFAQ-arrow ${activeIndex === index ? 'open' : ''}`}>▼</span></h3>
                        <p className="shortFAQ-item-content">{item.answer}</p>
                    </div>
                ))}
                <br/>
            </div>
        </div>
    );
};

export default ShortFAQ;
